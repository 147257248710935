<template>
  <el-upload
    :action="actionUrl"
    :headers="headers"
    :data="data"
    :show-file-list="false"
    :on-success="handleAvatarSuccess"
    :before-upload="beforeAvatarUpload"
    :on-progress="handleProgress"
    :on-error="handleError"
  >
    <slot>
      <el-button type="primary">
        <i class="el-icon-upload"></i>
        <span>Upload</span>
      </el-button>
    </slot>
  </el-upload>
</template>

<script>
import uploadPng from '@/assets/upload.png'
import Cookies from 'js-cookie'

const typeMapApi = {
  image: 'amazon/file/uploadImg',
  file: 'amazon/file/upload',
}
export default {
  props: {
    data: Object,
    type: {
      type: String,
      default: 'image'
    },
    api: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      uploadPng,
      actionUrl: `${
        process.env.NODE_ENV === 'development' ? '/restApi' : process.env.VUE_APP_REQUEST_URL
      }/${this.api ? this.api : typeMapApi[this.type]}`,
      imageUrl: '',
      headers: {
        Authorization: Cookies.get('tower-token')
      },
      loading: null
    }
  },
  methods: {
    handleProgress({ percent }) {
      // 避免 percent 到 100 了数据还在渲染中
      this.loading.text = `uploading... ${
        percent === 100 ? Math.floor(Math.random() * 10 + 90) : Math.floor(percent)
      }%`
    },
    handleError(err) {
      this.loading.close()
      // console.log('上传失败', err)
    },
    handleAvatarSuccess(res) {
      this.loading.close()
      // console.log('上传成功', res)
      if(res.code !== 200) return this.$message.error(res.msg)
      this.$emit('success', res.result)
    },
    beforeAvatarUpload(file) {
      // console.log(file.type)
      // const isJPG = ['image/jpeg', 'image/png', 'application/pdf'].includes(file.type)
      // const isLt2M = file.size / 1024 / 1024 <= 100

      // if (!isJPG) {
      //   this.$message.error('上传文件只能是 JPG、PNG、PDF 格式!')
      // }
      // if (!isLt2M) {
      //   this.$message.error('上传文件大小不能超过 100MB!')
      // }

      // if (isJPG && isLt2M) {
        this.loading = this.$loading({
          target: document.getElementsByClassName('simple-uploader')[0],
          text: 'uploading 0%',
          background: 'rgba(0, 0, 0, 0.6)'
        })
        return true
      // }
      // return false
    }
  }
}
</script>

<style lang="scss" scope>

</style>