<template>
  <el-dialog title="Add New Project" :visible.sync="dialogFormVisible" width="1000px">
    <el-form ref="ruleForm" :rules="rules" :model="form" label-width="120px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="Cover Image">
            <div v-if="form.imgUrl" class="cover-image">
              <i class="el-icon-delete" @click="form.imgUrl = ''"></i>
              <el-image :src="form.imgUrl"></el-image>
            </div>
            <file-upload v-else @success="postCoverImage"></file-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="Tower ID" prop="code">
            <el-input v-model="form.code" placeholder="Please enter"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Project Name" prop="name">
            <el-input v-model="form.name" placeholder="Please enter"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="Location" prop="location">
            <el-input v-model="form.location" placeholder="Please enter"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Latitude">
            <el-input v-model="form.lat" placeholder="Please enter"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="Longtitude">
            <el-input v-model="form.lng" placeholder="Please enter"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Depth">
            <el-input v-model="form.depth" placeholder="Please enter"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="Width">
            <el-input v-model="form.width" placeholder="Please enter"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Height">
            <el-input v-model="form.height" placeholder="Please enter"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="Categories" prop="catagories">
            <div class="flex-center">
              <el-radio v-model="form.catagories" :label="0" class="flex-center">
                <div class="flex-center">
                  <el-image :src="inspectedIcon"></el-image>
                  Inspection
                </div>
              </el-radio>
              <el-radio v-model="form.catagories" :label="1" class="flex-center">
                <div class="flex-center">
                  <el-image :src="monitoringIcon"></el-image>
                  Sensor Monitoring
                </div>
              </el-radio>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="S3 Path" v-if="form.catagories === 1">
            <el-input v-model="form.s3PathUrl" placeholder="Please enter"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">Cancel</el-button>
      <el-button type="primary" @click="submitData">Confirm</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import Monitoring from '@/assets/icons/home/Monitoring.png'
  import Inspected from '@/assets/icons/home/Inspected.png'

  import { addTower, editTower } from '@/api/tower-manage'
  import fileUpload from '@/components/upload/file-upload.vue'

  const defaultForm = {
    code: '',
    name: '',
    location: '',
    lng: '',
    lat: '',
    depth: '',
    width: '',
    catagories: '',
    s3PathUrl: '',
    imgUrl: '',
    height: ''
  }

  export default {
    components: {
      fileUpload
    },
    data() {
      return {
        type: 'create',
        dialogFormVisible: false,
        form: {
          code: '',
          name: '',
          location: '',
          lng: '',
          lat: '',
          depth: '',
          width: '',
          catagories: '',
          s3PathUrl: '',
          imgUrl: ''
        },
        formLabelWidth: '120px',
        monitoringIcon: Monitoring,
        inspectedIcon: Inspected,
        rules: {
          code: [{ required: true, message: 'Please enter Tower ID', trigger: 'blur' }],
          name: [{ required: true, message: 'Please enter Project Name', trigger: 'blur' }],
          location: [{ required: true, message: 'Please enter Location', trigger: 'blur' }],
          catagories: [{ required: true, message: 'Please choose Categories', trigger: 'blur' }],
        }
      }
    },

    methods: {
      open(row) {
        console.log('open', row)
        if (row) {
          this.form = Object.assign(this.form, row)
          this.type = 'edit'
        } else {
          this.form = JSON.parse(JSON.stringify(defaultForm))
          this.type = 'create'
        }
      
        this.dialogFormVisible = true
      },

      submitData() {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            if (this.type === 'create') {
              addTower(this.form).then((res) => {
                this.handleSuccess()
              }).catch(() => {})
            } else {
              editTower(this.form).then((res) => {
                this.handleSuccess()
              }).catch(() => {})
            }
          }
        })
      },

      handleSuccess() {
        this.dialogFormVisible = false
        this.$emit('success')
      },

      postCoverImage(file) {
        // console.log('file :>> ', file)
        this.form.imgUrl = file.path
      }
    }
  }
</script>

<style scoped lang="scss">
.flex-center {
  display: flex;
  align-items: center;
  height: 40px;
  .el-image {
    flex-shrink: 0;
    margin-right: 8px;
  }
}

.cover-image {
  width: 100px;
  height: 100px;
  position: relative;
  .el-icon-delete {
    font-size: 16px;
    color: red;
    position: absolute;
    bottom: 0;
    right: -20px;
    z-index: 9;
    cursor: pointer;
  }
}
</style>